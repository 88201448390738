import React, { useState, useEffect } from "react";
import HeatMap from "react-heatmap-grid";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Nav,
  NavItem,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import { FaInfoCircle } from "react-icons/fa";
import { Skeleton } from "@mui/material";

const xLabels = new Array(24).fill(0).map((_, i) => `${i}`);

const yLabels = [""];
const data = new Array(yLabels.length)
  .fill(0)
  .map(() => new Array(xLabels.length).fill(0));

export default function PacksHeatMapComponent({ filtersData, clientId }) {
  const [heatMapData, setHeatMapData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [performanceKey, setPerformanceKey] = useState("Packs");
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/mining/performance-heat-map`, {
        params: { ...filtersData, clientId, performance_key: performanceKey },
        headers: authHeader(),
      })
      .then((res) => {
        setHeatMapData(res.data.heatMapData);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      // console.log('Log');
    };
  }, [clientId, filtersData, performanceKey]);

  return isLoading ? (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              <Skeleton width={300} height={70} />
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <Skeleton width={100} height={70} />
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="text-white" >
        <Skeleton width={'100%'} height={100} />
      </CardBody>
    </Card>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              Performance per Hour
              <FaInfoCircle
                id="pph-heatmap-tooltip"
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={`pph-heatmap-tooltip`}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                Identify your most productive hours by knowing how many
                packs/weight are you packing in that hour every hour
              </Tooltip>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              {performanceKey === "Weight" && (
                <NavItem className="text-white pt-1">In Kg:</NavItem>
              )}
              <NavItem>
                <Input
                  placeholder="Performance Basis"
                  name="performanceKey"
                  type="select"
                  value={performanceKey}
                  className="textBg inline"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={(e) => setPerformanceKey(e.target.value)}
                >
                  {["Packs", "Weight"].map((option, idx) => {
                    return (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="text-white">
        <HeatMap
          xLabels={xLabels}
          yLabels={yLabels}
          xLabelsLocation={"bottom"}
          // xLabelsVisibility={xLabelsVisibility}
          // xLabelWidth={5}
          yLabelWidth={1}
          data={heatMapData}
          // squares
          height={50}
          // onClick={(x, y) => {
          //   handleClick(x, y);
          // }}
          cellStyle={(background, value, min, max, data, x, y) => {
            const val = value ? (value !== 0 ? value.split("@")[0] : 0) : 0;
            let arr1D_data = data.flat();
            arr1D_data = arr1D_data.map((item) =>
              item && item !== 0 ? parseFloat(item.split("@")[0]) : 0
            );
            let max1 = Math.max(...arr1D_data);
            let min1 = Math.min(...arr1D_data);
            return {
              background: `rgb(167, 205, 57, ${1 - (max1 - val) / (max1 - min1)
                })`,
              fontSize: "14px",
              color: "#fff",
              border: "1px solid grey",
            };
          }}
          cellRender={(value) =>
            value && value !== 0 && <div>{value.split("@")[0]}</div>
          }
        />
      </CardBody>
    </Card>
  );
}
