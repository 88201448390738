import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
// import deepEqual from "utils/deepequal";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { Skeleton } from "@mui/material";

function TopBatchesByGiveAway({ filtersData, setFiltersData, clientId }) {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    setChartData([]);
    // setChartLabels([]);
    axios
      .get(`${baseUrl}/api/mining/top-avg-GA-batches`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        let x = [];
        let y = [];
        // let hoverText = [];
        res.data.response.forEach((item, idx) => {
          x.push(`B-${item.Batch_ID}`);
          y.push(`${item.Total_GA}`);
        });
        const newData = [];
        res.data.response.forEach((item, idx) => {
          let barData = {
            x: x,
            y: y,
            type: "bar",
            yaxis: "y2",
            showlegend: idx === 0 ? true : false,
            name: "Top GA batches",
            marker: { color: "#a7ce39" },
            width: 0.3,
            line: item.Line,
            recipe: item.Recipe,
            line_leader: item.Line_Leader,
            customdata: res.data.response.map(el => [el.Line, el.Recipe, el.Line_Leader]),
            hovertemplate:
              "<b>Line</b>: %{customdata[0]} <br><b>Recipe</b>: %{customdata[1]} <br><b>Line Leader</b>: %{customdata[2]} <br><b>Total Give away</b>: %{y}<extra></extra>"
          };
          newData.push(barData);

        });

        setChartData(newData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setChartData([]);
        // setChartLabels([]);
      });
  }, [filtersData, clientId]);

  const layout = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: {
      title: "Total GA (grams)",
      // titlefont: { color: "rgb(167, 206, 57)" },
      // tickfont: { color: "rgb(167, 206, 57)" },
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
    // xaxis: {
    //   tickvals: chartData[0]?.x || [], // Ensure tickvals are set only when data is available
    //   ticktext: (chartData[0]?.x || []).map((label) =>
    //     label.length > 10 ? label.substring(0, 3) + "..." : label
    //   ),
    // },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent" style={{
        paddingBottom: "0px",
        paddingTop: "10px",
      }}>
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              <Skeleton width={200} height={60} />
            </h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Skeleton variant="rectangular" width={"100%"} height={450} />
      </CardBody>
    </Card>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">Give Away Analysis</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {chartData.length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {chartData && (
                <Plot
                  data={chartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={layout}
                  config={config}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default TopBatchesByGiveAway;
