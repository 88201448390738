import React, { useState } from "react";
import {
  Col,
  Card,
  Spinner,
  Button,
  CardDeck,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
// import Table from "views/shared/Table";
import {
  Grid,
  // TablePagination
} from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import AddEditModal from "./AddEditModal";
import ConfirmationModal from "views/shared/ConfirmationModal";
// import AuthService from "services/auth.service";
import VideoPlayerModal from "./VideoPlayerModal";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";

function TutorialsTable({
  // filtersData,
  fetchData,
  data,
}) {
  const [openVideoPlayerModal, setOpenVideoPlayerModal] = useState(false);
  const [videoPlayerModalData, setVideoPlayerModalData] = useState({});
  //   const { user } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    key: null,
    title: null,
    modalData: null,
    modalButtonText: null,
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({
    title: "",
    body: "",
    confirmText: "",
  });
  const [deleteTutorialData, setDeleteTutorialData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    axios
      .delete(`${baseUrl}/api/admin/tutorials/${deleteTutorialData.id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        fetchData();
        setIsDeleting(false);
        setOpenConfirmationModal((prevState) => !prevState);
        toast.success("Tutorial deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        setIsDeleting(false);
        toast.error("Failed to delete tutorial");
      });
  };

  return (
    <>
      <Col className="h-100">
        <Card className="shadow h-100">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h2 className="px-4 pt-4" style={{ color: "#a7ce39" }}>
              Tutorials
            </h2>
            <div className="mr-4 pt-3">
              <Button
                style={{ backgroundColor: "#a7ce39", border: "none" }}
                className="px-4"
                // size="sm"
                onClick={() => {
                  setOpenModal(true);
                  setInfoModalData({
                    key: "tutorial",
                    title: "Add New Tutorial",
                    modalData: null,
                    modalButtonText: "Add",
                  });
                }}
              >
                Add
                <i className="fa fa-plus ml-2" />
              </Button>
            </div>
          </Grid>
          <CardDeck className="px-4 py-2 mt-2">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={4}
            >
              {data === "Loading..." ? (
                Array(6).fill().map((tutorial, idx) => (
                  <Grid item xs={12} md={4} key={idx}>
                    <Card className="shadow h-100">
                      <Skeleton variant="rectangular" height={200} />
                      <CardBody className="d-flex flex-column">
                        <div className="mb-4">
                          <CardTitle tag="h2" className="text-white">
                            <Skeleton variant="text" width={100} />
                          </CardTitle>
                          <CardText>
                            <Skeleton variant="text" width={200} />
                          </CardText></div>
                        {/* Add your video player here */}
                        <div className="mt-auto">
                          <Button
                            color="info"
                            onClick={() => {
                              setOpenVideoPlayerModal(true);
                              setVideoPlayerModalData(tutorial);
                            }}
                          >
                            <Skeleton variant="text" width={50} />
                          </Button>
                          {/* Add your edit and delete buttons here */}
                          <Button
                            color="primary"
                            onClick={() => {
                              setOpenModal(true);
                              setInfoModalData({
                                key: "tutorial",
                                title: "Update Tutorial",
                                modalData: tutorial,
                                modalButtonText: "Update",
                              });
                            }}
                          >
                            <Skeleton variant="text" width={50} />

                          </Button>
                          <Button
                            color="danger"
                            onClick={() => {
                              setConfirmationModalData({
                                title: `Delete tutorial ${tutorial.title}`,
                                body: "Are you sure you want to delete this tutorial?",
                                confirmText: "Confirm",
                              });
                              setDeleteTutorialData(tutorial);
                              setOpenConfirmationModal(true);
                            }}
                          >
                            <Skeleton variant="text" width={50} />

                          </Button></div>
                      </CardBody>
                    </Card>
                  </Grid>
                ))
              ) : data ? (

                data.map((tutorial) => (
                  <Grid item xs={12} md={4}>
                    <Card key={tutorial.id} className="shadow h-100">
                      <video>
                        <source src={tutorial.video_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <CardBody className="d-flex flex-column">
                        <div className="mb-4">
                          <CardTitle tag="h2" className="text-white">{tutorial.title}</CardTitle>
                          <CardText>{tutorial.description}</CardText></div>
                        {/* Add your video player here */}
                        <div className="mt-auto">
                          <Button
                            color="info"
                            onClick={() => {
                              setOpenVideoPlayerModal(true);
                              setVideoPlayerModalData(tutorial);
                            }}
                          >
                            Play Tutorial
                          </Button>
                          {/* Add your edit and delete buttons here */}
                          <Button
                            color="primary"
                            onClick={() => {
                              setOpenModal(true);
                              setInfoModalData({
                                key: "tutorial",
                                title: "Update Tutorial",
                                modalData: tutorial,
                                modalButtonText: "Update",
                              });
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => {
                              setConfirmationModalData({
                                title: `Delete tutorial ${tutorial.title}`,
                                body: "Are you sure you want to delete this tutorial?",
                                confirmText: "Confirm",
                              });
                              setDeleteTutorialData(tutorial);
                              setOpenConfirmationModal(true);
                            }}
                          >
                            Delete
                          </Button></div>
                      </CardBody>
                    </Card>
                  </Grid>
                ))
              ) : (
                "Not Available"
              )}
            </Grid>
          </CardDeck>
        </Card>
      </Col>
      {openModal && (
        <AddEditModal
          isOpen={openModal}
          toggle={() => setOpenModal((prevState) => !prevState)}
          modalKey={infoModalData.key}
          modalTitle={infoModalData.title}
          modalData={infoModalData.modalData}
          modalButtonText={infoModalData.modalButtonText}
          fetchData={fetchData}
        // user={user}
        />
      )}
      {openVideoPlayerModal && (
        <VideoPlayerModal
          isOpen={openVideoPlayerModal}
          toggle={() => setOpenVideoPlayerModal((prevState) => !prevState)}
          modalData={videoPlayerModalData}
        />
      )}
      <ConfirmationModal
        isOpen={openConfirmationModal}
        toggle={() => setOpenConfirmationModal((prevState) => !prevState)}
        title={confirmationModalData.title}
        body={confirmationModalData.body}
        confirmText={confirmationModalData.confirmText}
        modalData={deleteTutorialData}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
      />
    </>
  );
}

export default TutorialsTable;
