import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Tooltip } from "reactstrap";
import Plot from "react-plotly.js";
import { FaInfoCircle } from "react-icons/fa";
import { Skeleton } from "@mui/material";

const backgroundColor = [
  "#3B2244",
  "#CD966B",
  "#004369",
  "#DB1F48",
  "#130170",
  "#b8fa89",
];

function InProgressPpmGraph({
  data,
  graphYAxisKey = "ppm",
  title = "Lines PPM",
  isLoading = true,
}) {
  const [chartData, setChartData] = useState([]);
  const [labelFormat, setFormat] = useState(" %H:%M \n %d %B (%a)");
  const [chartLayout, setChartLayout] = useState({
    showlegend: true,
    // responsive: true,
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title },
    xaxis: {
      tickformat: labelFormat,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
    datarevision: 0,
  });
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    let chartDataArray = [...chartData];
    console.log("data44", data);
    if (!data) return;

    data.forEach((item) => {
      let lineExist = chartDataArray.findIndex((obj) => obj.name === item.line);
      if (lineExist > -1) {
        if (item.currentStatus === "Offline") {
          chartDataArray.splice(lineExist, 1);
        } else {
          let timeExist = chartDataArray[lineExist].x.findIndex(
            (value) => value === item.timeStamp
          );
          if (timeExist === -1) {
            chartDataArray[lineExist].x.push(item.timeStamp);
            chartDataArray[lineExist].y.push(item[graphYAxisKey]);
            const newLayout = Object.assign({}, chartLayout);
            newLayout.datarevision++;
            setChartLayout(newLayout);
          }
        }
      } else {
        if (item.currentStatus !== "Offline") {
          console.log("graphYAxisKey", graphYAxisKey);
          console.log("item.graphData", item.graphData);
          chartDataArray.push({
            name: item.line,
            type: "line",
            borderColor: backgroundColor[5],
            backgroundColor: backgroundColor[5],
            //borderWidth: 2,
            x:
              item.initialReqKey === "yes"
                ? item.graphData.x
                : [item.timeStamp],
            y:
              item.initialReqKey === "yes"
                ? item.graphData.y
                : [item[graphYAxisKey]],
          });
          const newLayout = Object.assign({}, chartLayout);
          newLayout.datarevision++;
          setChartLayout(newLayout);
        }
      }
    });

    setChartData(chartDataArray);
    setFormat(" %H:%M \n %d %B (%a)");
    return () => {
      setChartData([]);
      const newLayout = Object.assign({}, chartLayout);
      newLayout.datarevision = 0;
      setChartLayout(newLayout);
    };
  }, [data]);

  const config = {
    displayModeBar: false,
  };

  const formattedChartData = chartData;

  return (
    isLoading ? <Card className="shadow-sm">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              {title}{" "}
              <FaInfoCircle
                id="lines-ppm-chart-tooltip"
                fontSize={"18px"}
                className="ml-2"
              />
            </h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody style={{ paddingTop: 0 }}>
        <div className="chart" style={{ height: "auto" }}>
          <div class="chart-container-bar">
            <Skeleton variant="rectangular" height={400} />
          </div>
        </div>
      </CardBody>
    </Card> : formattedChartData.length > 0 && (
      <Card className="shadow-sm">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h2 className="mb-0 text-color-mw">
                {title}{" "}
                <FaInfoCircle
                  id="lines-ppm-chart-tooltip"
                  fontSize={"18px"}
                  className="ml-2"
                />
                <Tooltip
                  placement={"top"}
                  isOpen={tooltip}
                  target={"lines-ppm-chart-tooltip"}
                  toggle={() => {
                    setTooltip((st) => !st);
                  }}
                >
                  Each line productivity in terms of realtime average speed of
                  number of packs per minute
                </Tooltip>
              </h2>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {chartData && (
                <Plot
                  data={formattedChartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={chartLayout}
                  config={config}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    )
  );
}

export default InProgressPpmGraph;
