import { Skeleton } from "@mui/material";
import React from "react";
import { Progress, Row, Col } from "reactstrap";

function LineProgressBar({ progressValue, isLoading = true }) {
  const style = {
    height: "30px",
  };
  if (!progressValue) return null;

  const max = 100;

  return isLoading ? <Row className="mx-2 align-items-center justify-content-center">
    <Col xs="3">
      <span style={{ color: "#a7ce39", fontWeight: "bolder" }}>
        Batch Progress
      </span>
    </Col>
    <Col xs="9" className="mt-3">
      <Skeleton variant="rectangular" height={30} />
    </Col>
  </Row> : (
    <Row className="mx-2 align-items-center justify-content-center">
      <Col xs="3">
        <span style={{ color: "#a7ce39", fontWeight: "bolder" }}>
          Batch Progress
        </span>
      </Col>
      <Col xs="9" className="mt-3">
        <Progress
          value={progressValue}
          className="current-bar"
          max={max}
          style={style}
        >
          <span className="text-dark font-weight-bold">
            {progressValue + "%"}
          </span>
        </Progress>
      </Col>
    </Row>
  );
}

export default LineProgressBar;
