import React, { useState } from "react";
import { Card, Grid } from "@material-ui/core";
import Filters from "./Filters.jsx";
import { CardBody, Col, Container, Row } from "reactstrap";
// import PacksKpiGraph from "./PacksKpiGraph.js";
import AvgWeightGraph from "./AvgWeightGraph.js";
// import OperatorsGraph from "./TopOperatorsGraph.jsx";
import Operators from "./Operators.js";
import OperatorsCostTable from "./OperatorsCostTable.js";
// import PacksHeatMapComponent from "./PacksHeatmap.js";
// import PacksDataContainer from "./PacksDataContainer.js";
import ProductBrandBarGraphContainer from "./ProductBrandBarGraphContainer.jsx";
import PacksKpiGraph from "./PacksKpiGraph.js";
import PacksHeatMapComponent from "./PacksHeatmap.js";
import TopValuesBar from "views/AnalyticsBoard/TopValuesBar.jsx";
import DailyAverageGiveAway from "./DailyAverageGiveAway.js";
import TopBatchesByGiveAway from "./TopBatchesByGiveAway.js";

function Index({ clientId, clientSubscription, timeRange, setTimeRange }) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 7);

  const [filtersData, setFiltersData] = useState({
    start_date: yesterday.toISOString(),
    end_date: today.toISOString(),
    product: "All",
    brand: "All",
    tier: "All",
  });

  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    // className="px-5"
    >
      <Filters
        filtersData={filtersData}
        handleApplyFilters={handleApplyFilters}
        clientId={clientId}
        clientSubscription={clientSubscription}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
      <Container className="bg-transparent" fluid>
        <Row className="my-4">
          <Col xs="12" md="6" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%", }}
            >
              <CardBody className="p-0 h-100" style={{ minHeight: '560px' }}>
                <AvgWeightGraph
                  title={"Average Weight"}
                  filtersData={filtersData}
                  setFiltersData={setFiltersData}
                  clientId={clientId}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="6" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%" }}
            >
              <CardBody className="p-0 h-100" style={{ minHeight: '560px' }}>
                <PacksKpiGraph
                  filtersData={filtersData}
                  setFiltersData={setFiltersData}
                  clientId={clientId}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="12" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%" }}
            >
              <CardBody className="p-0 h-100">
                <PacksHeatMapComponent
                  filtersData={filtersData}
                  clientId={clientId}
                />
              </CardBody>
            </Card>
          </Col>
          {/* <PacksDataContainer clientId={clientId} filtersData={filtersData} /> */}
          <ProductBrandBarGraphContainer
            clientId={clientId}
            filtersData={filtersData}
          />
          {/* <Col xs="12" md="6" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%" }}
            >
              <CardBody className="p-0">
                <PacksKpiGraph
                  title={"Packs"}
                  labelData={["Labour £", "GA £", "Pack £", "Total £"]}
                  filtersData={filtersData}
                  setFiltersData={setFiltersData}
                  clientId={clientId}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="12" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%" }}
            >
              <CardBody className="p-0">
                <PacksHeatMapComponent
                  clientId={clientId}
                  timePeriod={filtersData}
                />
              </CardBody>
            </Card>
          </Col> */}
          <Col xs="12" md="12" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%" }}
            >
              <CardBody className="p-0 h-100" >
                <OperatorsCostTable
                  clientId={clientId}
                  filtersData={filtersData}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="12" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%", minHeight: '560px' }}
            >
              <CardBody className="p-0">
                <Operators clientId={clientId} filtersData={filtersData} />
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="6" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%", minHeight: '560px' }}
            >
              <CardBody className="p-0 h-100">
                <DailyAverageGiveAway
                  filtersData={filtersData}
                  setFiltersData={setFiltersData}
                  clientId={clientId}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="6" className="mb-4">
            <Card
              className="card-stats bg-transparent"
              style={{ height: "100%", minHeight: '560px' }}
            >
              <CardBody className="p-0 h-100">
                <TopBatchesByGiveAway
                  filtersData={filtersData}
                  setFiltersData={setFiltersData}
                  clientId={clientId}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Grid>
  );
}

export default Index;
