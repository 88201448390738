import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  Tooltip,
} from "reactstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AvgWeightSpeedGraph from "./AvhWeightSpeedGraph";
import OperatorsInfo from "./OperatorsInfo";
import LineProgressBar from "./LineProgressBar";
import { baseUrl } from "constants/url";
import axios from "axios";
import authHeader from "services/auth-header";
import { FaInfoCircle } from "react-icons/fa";
import { Skeleton } from "@mui/material";

const styles = makeStyles((theme) => ({
  MuiAccordian: {
    "& .MuiAccordionSummary-content": {
      display: "none",
    },
    "& .MuiAccordionSummary-expandIcon": {
      color: "#fff",
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "unset",
      height: 10,
      paddingTop: 15,
    },
  },
}));

function InProgressLines({
  data,
  packsData,
  avgWeightData,
  clientId,
  isLoading = true,
}) {
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [tooltipTable, setTooltipTable] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const today = new Date();
  const todayGetData = new Date();
  const yesterdayGetData = new Date(today);

  const handleGenerateExcelReport = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/dashboard/operators-info-report`,
        {
          params: {
            start_date: yesterdayGetData.toISOString(),
            // start_date: "2016-01-01T00:00:00Z",
            end_date: todayGetData.toISOString(),
            // end_date: "2022-08-04T23:59:59Z",
            clientId,
            lineName: data.line,
          },
          headers: authHeader(),
        }
      );

      window.open(res.data.url, "_blank");
    } catch (error) {
      console.log("error", error);
    }
  };

  const classes = styles();
  return isLoading ? (
    <Card className="">
      <CardHeader className="bg-transparent" style={{ width: "100%" }}>
        <Row className="align-items-center justify-space-between">
          <div className="col col-3">
            <h2 className="mb-0 text-color-mw">
              <Skeleton variant="text" width={100} />
              <FaInfoCircle
                id="line-accordion-tooltip"
                fontSize={"18px"}
                className="ml-2"
              />
            </h2>
          </div>
          <div className="col col-6">
            <LineProgressBar isLoading={isLoading} />
          </div>
          <div className="col col-3 text-right">
            <h2
              className="mb-0 text-color-mw"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              Current State: <Skeleton variant="text" width={100} />
            </h2>
          </div>
        </Row>
      </CardHeader>
      <Accordion
        className={classes.MuiAccordian}
        style={{
          backgroundColor: "transparent",
        }}
      >
        <CardBody>
          <Row className="mb-3">
            <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="4">
              <Card className="shadow-sm">
                <CardBody className="p-2">
                  <div className="col">
                    <CardTitle tag="h2" className="text-muted mb-0">
                      Recipe
                    </CardTitle>
                    <span className="h2 font-weight-bold text-white mb-0">
                      <Skeleton variant="text" width={100} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="1">
              <Card className="shadow-sm">
                <CardBody className="p-2">
                  <div className="col">
                    <CardTitle tag="h2" className="text-muted mb-0">
                      Packs
                    </CardTitle>
                    <span className="h2 font-weight-bold text-white mb-0">
                      <Skeleton variant="text" width={100} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="1">
              <Card className="shadow-sm">
                <CardBody className="p-2">
                  <div className="col">
                    <CardTitle tag="h2" className="text-muted mb-0">
                      KPI %
                    </CardTitle>
                    <span className="h2 font-weight-bold text-white mb-0">
                      <Skeleton variant="text" width={100} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="1">
              <Card className="shadow-sm">
                <CardBody className="p-2">
                  <div className="col">
                    <CardTitle tag="h2" className="text-muted mb-0">
                      -T1 %
                    </CardTitle>
                    <span className="h2 font-weight-bold text-white mb-0">
                      <Skeleton variant="text" width={100} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="4">
              <Card className="shadow-sm">
                <CardBody className="p-2">
                  <div className="col">
                    <CardTitle tag="h2" className="text-muted mb-0">
                      GA Percentage
                    </CardTitle>
                    <span className="h2 font-weight-bold text-white mb-0">
                      <Skeleton variant="text" width={100} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          ></AccordionSummary>
        </CardBody>
        <AccordionDetails>
          <FaInfoCircle
            id="line-table-tooltip"
            fontSize={"18px"}
            className="ml-2 text-white"
          />
          <Tooltip
            placement={"top"}
            isOpen={tooltipTable}
            target={`line-table-tooltip`}
            toggle={() => {
              setTooltipTable((st) => !st);
            }}
          >
            These numbers and operators are for the running batch only
          </Tooltip>
          <OperatorsInfo clientId={clientId} isLoading={isLoading} />
        </AccordionDetails>
      </Accordion>
    </Card>
  ) : (
    <Card className="">
      <CardHeader className="bg-transparent" style={{ width: "100%" }}>
        <Row className="align-items-center justify-space-between">
          <div className="col col-3">
            <h2 className="mb-0 text-color-mw">
              {data.line}
              <FaInfoCircle
                id="line-accordion-tooltip"
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={`line-accordion-tooltip`}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                Recipe being packed on this line at the moment - Number of packs
                packed for this batch so far - KPI of the batch so far
              </Tooltip>
            </h2>
          </div>
          <div className="col col-6">
            <LineProgressBar
              progressValue={(data.completed && data.completed.toFixed()) || 0}
              isLoading={isLoading}
            />
          </div>
          <div className="col col-3 text-right">
            <h2 className="mb-0 text-color-mw">
              Current State: {data.currentStatus}
            </h2>
          </div>
        </Row>
      </CardHeader>
      {data.currentStatus !== "Offline" && (
        <Accordion
          className={classes.MuiAccordian}
          style={{
            backgroundColor: "transparent",
          }}
        >
          <CardBody>
            <Row className="mb-3">
              <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="4">
                <Card className="shadow-sm">
                  <CardBody className="p-2">
                    <div className="col">
                      <CardTitle tag="h2" className="text-muted mb-0">
                        Recipe
                      </CardTitle>
                      <span className="h2 font-weight-bold text-white mb-0">
                        {data.Recipe ? data.Recipe : data.recipe}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="1">
                <Card className="shadow-sm">
                  <CardBody className="p-2">
                    <div className="col">
                      <CardTitle tag="h2" className="text-muted mb-0">
                        Packs
                      </CardTitle>
                      <span className="h2 font-weight-bold text-white mb-0">
                        {data.packs}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="1">
                <Card className="shadow-sm">
                  <CardBody className="p-2">
                    <div className="col">
                      <CardTitle tag="h2" className="text-muted mb-0">
                        KPI %
                      </CardTitle>
                      <span className="h2 font-weight-bold text-white mb-0">
                        {data && data.kpi.toFixed(2)}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="1">
                <Card className="shadow-sm">
                  <CardBody className="p-2">
                    <div className="col">
                      <CardTitle tag="h2" className="text-muted mb-0">
                        -T1 %
                      </CardTitle>
                      <span className="h2 font-weight-bold text-white mb-0">
                        {data && data.t1}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="4">
                <Card className="shadow-sm">
                  <CardBody className="p-2">
                    <div className="col">
                      <CardTitle tag="h2" className="text-muted mb-0">
                        GA Percentage
                      </CardTitle>
                      <span className="h2 font-weight-bold text-white mb-0">
                        {data &&
                          (
                            ((+data.Set_Point - +data.avgWeight) /
                              +data.Set_Point) *
                            100
                          ).toFixed(2)}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            ></AccordionSummary>
          </CardBody>
          <AccordionDetails>
            {/* <button
              className="btn"
              onClick={handleGenerateExcelReport}
              //   href={ReportPdf}
              //   target="_blank"
              //   rel="noreferrer"
              style={{
                backgroundColor: "#a7ce39",
                border: "none",
                color: "black",
                minWidth: "100",
              }}
            >
              {isLoadingExcel ? (
                <CircularProgress size={20} />
              ) : (
                "Generate Excel Report"
              )}
            </button> */}
            <FaInfoCircle
              id="line-table-tooltip"
              fontSize={"18px"}
              className="ml-2 text-white"
            />
            <Tooltip
              placement={"top"}
              isOpen={tooltipTable}
              target={`line-table-tooltip`}
              toggle={() => {
                setTooltipTable((st) => !st);
              }}
            >
              These numbers and operators are for the running batch only
            </Tooltip>
            <OperatorsInfo
              clientId={clientId}
              lineName={data.line}
              isLoading={isLoading}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Card>
  );
}

export default InProgressLines;
