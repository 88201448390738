import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  Tooltip,
} from "reactstrap";

function CustomizedCardData({
  isLoading,
  cardImage,
  cardData,
  message,
  toolTipData,
  revert_order = false,
}) {
  const [tooltip, setTooltip] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);

  return (
    <Card className="card-stats w-100" style={{ height: "100%" }}>
      <CardBody className="">
        <Row>
          <Col>
            <CardTitle tag="h3" className="text-color-mw my-0">
              {cardData.title}
              {/* <FaInfoCircle
                id={toolTipData.id}
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={toolTipData.id}
                toggle={() => {
                  !isLoading && setTooltip((st) => !st);
                }}
              >
                {toolTipData.message}
              </Tooltip> */}
            </CardTitle>
            {message === "No data found" ? (
              <span className="h4 text-muted font-weight-bold my-1">
                No Data Found
              </span>
            ) : isLoading ? (
              <Skeleton width={100} height={50} />
            ) : (
              <Row>
                <Col>
                  <span className="h3 text-white font-weight-bold my-1">
                    {cardData.count}{" "}
                    <FaInfoCircle
                      id={`${toolTipData.id}-count-tooltip`}
                      fontSize={"12px"}
                      className="ml-2"
                    />
                    <Tooltip
                      placement={"bottom"}
                      isOpen={tooltip}
                      target={`${toolTipData.id}-count-tooltip`}
                      toggle={() => {
                        setTooltip((st) => !st);
                      }}
                    >
                      {toolTipData.message} with Idle time on Scales
                    </Tooltip>
                  </span>
                  {isLoading && (
                    <p
                      className="mb-0 text-muted text-sm"
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <span
                        className={
                          cardData.sinceValue.includes("decrease")
                            ? revert_order
                              ? "text-success mr-2"
                              : "text-warning mr-2"
                            : "text-warning mr-2"
                        }
                      >
                        <Skeleton width={150} height={50} />
                      </span>{" "}
                      <span className="text-nowrap">
                        <Skeleton width={150} height={50} />
                      </span>
                    </p>
                  )}
                  {!isLoading &&
                    message !== "No data found" &&
                    cardData.durationDays !== 0 && (
                      <p className="mb-0 text-muted text-sm">
                        <span
                          className={
                            cardData.sinceValue.includes("decrease")
                              ? revert_order
                                ? "text-success mr-2"
                                : "text-warning mr-2"
                              : "text-warning mr-2"
                          }
                        >
                          <i
                            className={
                              cardData.sinceValue.includes("decrease")
                                ? "fas fa-arrow-down"
                                : "fa fa-arrow-up"
                            }
                          />{" "}
                          {cardData.sinceValue}
                        </span>{" "}
                        <span className="text-nowrap">
                          than previous {cardData.durationDays} days
                        </span>
                      </p>
                    )}
                </Col>
                <Col>
                  <span className="h3 text-white font-weight-bold my-1">
                    {cardData.zeroCount}
                    <FaInfoCircle
                      id={`${toolTipData.id}-zero-count-tooltip`}
                      fontSize={"12px"}
                      className="ml-2"
                    />
                    <Tooltip
                      placement={"bottom"}
                      isOpen={tooltip2}
                      target={`${toolTipData.id}-zero-count-tooltip`}
                      toggle={() => {
                        setTooltip2((st) => !st);
                      }}
                    >
                      {toolTipData.message} ignoring Idle time on Scales
                    </Tooltip>
                  </span>
                  {isLoading && (
                    <p
                      className="mb-0 text-muted text-sm"
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <span
                        className={
                          cardData.zeroSinceValue.includes("decrease")
                            ? revert_order
                              ? "text-success mr-2"
                              : "text-warning mr-2"
                            : "text-warning mr-2"
                        }
                      >
                        <Skeleton width={150} height={50} />
                      </span>{" "}
                      <span className="text-nowrap">
                        <Skeleton width={150} height={50} />
                      </span>
                    </p>
                  )}
                  {!isLoading &&
                    message !== "No data found" &&
                    cardData.durationDays !== 0 && (
                      <p className="mb-0 text-muted text-sm">
                        <span
                          className={
                            cardData.zeroSinceValue.includes("decrease")
                              ? revert_order
                                ? "text-success mr-2"
                                : "text-warning mr-2"
                              : "text-warning mr-2"
                          }
                        >
                          <i
                            className={
                              cardData.zeroSinceValue.includes("decrease")
                                ? "fas fa-arrow-down"
                                : "fa fa-arrow-up"
                            }
                          />{" "}
                          {cardData.zeroSinceValue}
                        </span>{" "}
                        <span className="text-nowrap">
                          than previous {cardData.durationDays} days
                        </span>
                      </p>
                    )}
                </Col>
              </Row>
            )}
          </Col>
          <Col className="col-auto">
            <div
              className="icon icon-shape text-white rounded-circle shadow"
              style={{
                backgroundColor: "#731e2f",
                width: "70px",
                height: "70px",
              }}
            >
              <img src={cardImage} width={40} alt="" />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default CustomizedCardData;
