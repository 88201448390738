import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Spinner,
  Input,
} from "reactstrap";
import authHeader from "services/auth-header";
import { baseUrl } from "../../constants/url";
import axios from "axios";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import Select from "react-select";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#a7ce39" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#a7ce39" : "#a7ce39",
    },
  }),
  menu: (base) => ({
    ...base,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    "::-webkit-scrollbar": {
      display: "none",
    },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#a7ce39" : null,
      color: isFocused ? "black" : "white",
      // color: "#333333",
    };
  },
};

function RecipeAuditTrail({ isOpen, toggle, recipeData, clientId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState("All");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${baseUrl}/api/admin/recipe-audit/${clientId}/${recipeData.id}`,
          {
            headers: authHeader(),
          }
        );
        if (isMounted) {
          const recipeLog = response.data.recipeLog;
          setTableData(recipeLog);
          setFilteredData((prev) => (prev === recipeLog ? prev : recipeLog)); // Avoid redundant state update
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        if (isMounted) setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup flag on component unmount
    };
  }, [clientId, recipeData.id]);

  const handleSearch = (event) => {
    let value = event.value;
    if (value === "All") value = "";
    setSearchQuery(value);
    setPage(0);
    const query = value.toLowerCase();
    const filtered = tableData.filter(
      (recipe) => recipe.action_type.toLowerCase().includes(query) // Adjust field as necessary
    );
    setFilteredData(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      id: "action_type",
      title: "Action",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "recipe_name",
      title: "Recipe",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "timestamp",
      title: "Time of Change",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "user_name",
      title: "User",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
  ];

  return (
    <Modal
      scrollable
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-bg"
      backdropClassName="modal-backdrop-opacity"
      className="modal-width"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <div className="text-white rounded-circle">
              <i className="fas fa-times" />
            </div>
          </button>
        }
      >
        <h2 style={{ color: "#a7ce39" }}>Recipe Audit Trail</h2>
      </ModalHeader>
      <ModalBody className="p-3">
        {isLoading ? (
          <Row>
            <Col xs="12" className="text-center">
              <Spinner />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs="12">
              {/* <Input
                placeholder="Search by action"
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                className="textBg w-25"
                bsSize="sm"
                style={{ color: "#000000" }}
              /> */}
              <Select
                placeholder="Select Client..."
                name="clientId"
                value={{ label: searchQuery, value: searchQuery }}
                onChange={handleSearch}
                className="w-25"
                styles={customStyles}
                options={[
                  { label: "All", value: "All" },
                  { label: "create", value: "create" },
                  { label: "update", value: "update" },
                  { label: "delete", value: "delete" },
                  { label: "upload", value: "upload" },
                ]}
              />
            </Col>
            <Col xs="12" className="text-center">
              <Table
                values={filteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )}
                columns={columns}
                tableClasses={{
                  table:
                    "table align-items-center table-flush table-sm text-white table-custom",
                  thead: "",
                  tbody: "list table-td-color",
                }}
              />
              <TablePagination
                component="div"
                className="text-muted"
                count={filteredData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{" "}
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
}

export default RecipeAuditTrail;
