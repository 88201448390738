import { Skeleton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Card, CardBody, CardTitle, Tooltip } from "reactstrap";

function OverallLinesCardData({ title, value, toolTipData, isLoading }) {
  const [tooltip, setTooltip] = useState(false);

  return isLoading ? <Card className="shadow-sm">
    <CardBody className="p-2">
      <div className="col">
        <CardTitle tag="h2" className="text-muted mb-0">
          {title}
          <FaInfoCircle
            id={Math.random().toString(36).substring(7)}
            fontSize={"18px"}
            className="ml-2"
          />
        </CardTitle>
        <span className="h2 font-weight-bold text-white mb-0">
          <Skeleton variant="text" width={50} />
        </span>
      </div>
    </CardBody>
  </Card> : (
    <Card className="shadow-sm">
      <CardBody className="p-2">
        <div className="col">
          <CardTitle tag="h2" className="text-muted mb-0">
            {title}
            <FaInfoCircle
              id={toolTipData.id}
              fontSize={"18px"}
              className="ml-2"
            />
            <Tooltip
              placement={"top"}
              isOpen={tooltip}
              target={toolTipData.id}
              toggle={() => {
                setTooltip((st) => !st);
              }}
            >
              {toolTipData.message}
            </Tooltip>
          </CardTitle>
          <span className="h2 font-weight-bold text-white mb-0">{value}</span>
        </div>
      </CardBody>
    </Card>
  );
}

export default OverallLinesCardData;
