import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
  Tooltip,
} from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { rgb } from "d3";
import deepEqual from "utils/deepequal";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
import { Skeleton } from "@mui/material";

function AvgWeightGraph({ title, filtersData, setFiltersData, clientId }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isWeightsLoading, setIsWeightsLoading] = useState(false);
  const [filter, setFilter] = useState({ ...filtersData });
  const [weightList, setWeightList] = useState([]);
  const [weight, setWeight] = useState(null);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    setIsWeightsLoading(true);
    axios
      .get(`${baseUrl}/api/admin/getUniqueWeightsFromMinuteScaleTransactions`, {
        params: { ...filter, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        setWeightList(res.data.uniqueWeights);
        setWeight(parseInt(res.data.uniqueWeights[0]));
        setIsWeightsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsWeightsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (deepEqual(filtersData, filter)) return;
    setFilter({ ...filtersData });
  }, [filtersData, clientId]);

  useEffect(() => {
    if (isWeightsLoading || !weight) return;
    setIsLoading(true);
    setChartData([]);
    setChartLabels([]);
    axios
      .get(`${baseUrl}/api/mining/avgWeightGraph`, {
        params: { ...filter, clientId, weight },
        headers: authHeader(),
      })
      .then((res) => {
        let labels = [];
        let data = {
          avgWeight: [],
          setPoint: [],
          recipeWeight: [],
        };
        const start_date = moment(filter.start_date);
        const end_date = moment(filter.end_date);

        const days = end_date.diff(start_date, "days");
        res.data.response.data.forEach((item) => {
          labels.push(days > 61 ? moment(item.x).format("MMMM YYYY") : item.x);
          data.avgWeight.push(item.y);
        });
        res.data.response.setPoint.forEach((item) => {
          labels.push(days > 61 ? moment(item.x).format("MMMM YYYY") : item.x);
          data.setPoint.push(item.y);
        });
        res.data.response.recipeWeight.forEach((item) => {
          labels.push(days > 61 ? moment(item.x).format("MMMM YYYY") : item.x);
          data.recipeWeight.push(item.y);
        });
        setChartLabels(labels);
        setChartData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [filter, clientId, weight, isWeightsLoading]);

  const avgWeightGraph = {
    x: chartLabels,
    y: chartData && chartData.avgWeight,
    type: "line",
    name: "Average Weight",
    marker: { color: rgb(167, 0, 57) },
  };

  const setPointGraph = {
    x: chartLabels,
    y: chartData && chartData.setPoint,
    type: "line",
    name: "Set Point",
    marker: { color: "#5661c8" },
  };

  const recipeWeightGraph = {
    x: chartLabels,
    y: chartData && chartData.recipeWeight,
    type: "line",
    name: "Recipe Weight",
    marker: { color: "#fcba03" },
  };

  const formattedChartData = [avgWeightGraph, setPointGraph, recipeWeightGraph];

  const options2 = {
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: {
      title: "Average Weight (grams)",
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false,
  };

  return isLoading ? (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div
            className="col text-color-mw"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2 className="mb-0 text-color-mw">
              <Skeleton width={100} height={70} />
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem className="text-white pt-1">
                <Skeleton width={100} height={70} />
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Skeleton variant="rectangular" width={"100%"} height={450} />
      </CardBody>
    </Card>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div
            className="col text-color-mw"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2 className="mb-0 text-color-mw">{title}</h2>
            <FaInfoCircle
              id={"xy-tooltip"}
              fontSize={"18px"}
              className="ml-2"
            />
            <Tooltip
              placement={"top"}
              isOpen={tooltip}
              target={"xy-tooltip"}
              toggle={() => {
                setTooltip((st) => !st);
              }}
            >
              This graph shows the average weight, set point and recipe weight
              of the selected client.
            </Tooltip>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem className="text-white pt-1">Weights:</NavItem>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="country_id"
                  type="select"
                  value={weight}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={(e) => setWeight(parseInt(e.target.value))}
                >
                  {weightList.map((option, idx) => {
                    return (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {chartLabels.length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {chartData && (
                <Plot
                  data={formattedChartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={options2}
                  config={config}
                  onRelayout={(e) => {
                    if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                      setFiltersData({
                        ...filtersData,
                        start_date: new Date(e["xaxis.range[0]"]).toISOString(),
                        end_date: new Date(e["xaxis.range[1]"]).toISOString(),
                      });
                    }
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="m-5 text-muted">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default AvgWeightGraph;
