import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  // Nav,
  // NavItem,
  // Input,
} from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import moment from "moment";
import { Skeleton } from "@mui/material";
// import moment from "moment";
// import deepEqual from "utils/deepequal";

function PacksKpiGraph({ filtersData, setFiltersData, clientId }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [filter, setFilter] = useState({ ...filtersData });
  // const [duration, setDuration] = useState("");
  // const [labelFormat, setFormat] = useState("");

  // const onChange = (event) => {
  //   const value = event.target.value;
  //   setDuration(value);
  //   if (value === "") {
  //     setFilter({
  //       ...filtersData,
  //     });
  //   } else if (value === "Last Week") {
  //     const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
  //     const end = moment().utcOffset(0, true).format();
  //     setFilter((prevState) => {
  //       return {
  //         ...prevState,
  //         start_date: start,
  //         end_date: end,
  //       };
  //     });
  //   } else if (value === "Last Month") {
  //     const start = moment().subtract(1, "months").utcOffset(0, true).format();
  //     const end = moment().utcOffset(0, true).format();
  //     setFilter((prevState) => {
  //       return {
  //         ...prevState,
  //         start_date: start,
  //         end_date: end,
  //       };
  //     });
  //   } else if (value === "Last Year") {
  //     const start = moment().subtract(1, "years").utcOffset(0, true).format();
  //     const end = moment().utcOffset(0, true).format();
  //     setFilter((prevState) => {
  //       return {
  //         ...prevState,
  //         start_date: start,
  //         end_date: end,
  //       };
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (deepEqual(filtersData, filter)) return;
  //   setFilter({ ...filtersData });
  // }, [filtersData, clientId]);

  useEffect(() => {
    setIsLoading(true);
    setChartLabels([]);
    setChartData([]);
    axios
      .get(`${baseUrl}/api/mining/packsGraph`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        let labels = [];
        let data = [];
        const start_date = moment(filtersData.start_date);
        const end_date = moment(filtersData.end_date);

        const days = end_date.diff(start_date, "days");

        res.data.response.forEach((item) => {
          // labels.push(item.x);
          labels.push(days > 61 ?
            // only month and year
            moment(item.x).format("MMMM YYYY")
            : item.x);
          data.push(item.y);
        });
        setChartLabels(labels);
        // setFormat(res.data.days > 7 ? "%d %B \n %Y" : " %h:%M \n %d %B (%a)"); // setting the format for labels
        setChartData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [filtersData, clientId]);

  // useEffect(() => {
  //   let labels = [];
  //   let dataX = [];
  //   data?.forEach((item) => {
  //     labels.push(item.x);
  //     dataX.push(item.y);
  //   });
  //   setChartLabels(labels);
  //   // setFormat(res.data.days > 7 ? "%d %B \n %Y" : " %h:%M \n %d %B (%a)"); // setting the format for labels
  //   setChartData(dataX);
  // }, [data]);

  const myData = {
    x: chartLabels,
    y: chartData && chartData,
    type: "line",
    // mode: "lines",
    name: "Packs",
    marker: { color: "#fcba03" },
  };

  const formattedChartData = [myData];

  const options2 = {
    //title: 'KPI',
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: {
      title: "Packs",
      // titlefont: { color: "rgb(167, 206, 57)" },
      // tickfont: { color: "rgb(167, 206, 57)" },
    },
    // yaxis2: {
    //   title: "GA Cost",
    //   titlefont: { color: "rgb(167, 206, 57)" },
    //   tickfont: { color: "rgb(167, 206, 57)" },
    //   overlaying: "y",
    //   side: "right",
    // },
    xaxis: {
      // tickformat: labelFormat,
      // titlefont: { color: "rgb(167, 206, 57)" },
      // tickfont: { color: "rgb(167, 206, 57)" },
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              <Skeleton width={100} height={70} />
            </h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Skeleton variant="rectangular" width={"100%"} height={450} />
      </CardBody>
    </Card>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">Packs</h2>
          </div>
          {/* <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="country_id"
                  type="select"
                  value={duration}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={onChange}
                >
                  {["", "Last Week", "Last Month", "Last Year"].map(
                    (option, idx) => {
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    }
                  )}
                </Input>
              </NavItem>
            </Nav>
          </div> */}
        </Row>
      </CardHeader>
      <CardBody>
        {chartLabels.length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              <Plot
                data={formattedChartData}
                className="w-100 h-100"
                useResizeHandler
                layout={options2}
                config={config}
                onRelayout={(e) => {
                  if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                    setFiltersData({
                      ...filtersData,
                      start_date: new Date(e["xaxis.range[0]"]).toISOString(),
                      end_date: new Date(e["xaxis.range[1]"]).toISOString(),
                    });
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default PacksKpiGraph;
