import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { Skeleton } from "@mui/material";

function OpPerformance({ clientId, timePeriod }) {
  const [chartData, setChartData] = useState([]);
  const [timeChartData, setTimeChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [timeChartLabels, setTimeChartLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setChartData([]);
    setChartLabels([]);
    axios
      .get(
        `${baseUrl}/api/operatorPerformance/operatorPerformanceChart/${clientId}`,
        {
          params: { ...timePeriod },
          headers: authHeader(),
        }
      )
      .then((res) => {
        let tempChartData = [];
        let tempLabels = [];
        let tempTimeChartData = [];
        let tempTimeLabels = [];

        res.data.data.forEach((item) => {
          tempChartData.push(item.Total_Packs);
          tempLabels.push(item.Op_Name);
        });

        res.data.timeRes.forEach((item) => {
          tempTimeChartData.push(item.Time_in_Mins);
          tempTimeLabels.push(item.Op_Name);
        });

        setChartData(tempChartData);
        setChartLabels(tempLabels);
        setTimeChartData(tempTimeChartData);
        setTimeChartLabels(tempTimeLabels);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
        setTimeChartData([]);
        setTimeChartLabels([]);
      });
  }, [clientId, timePeriod]);

  const topOp = {
    x: chartData,
    y: chartLabels,
    type: "bar",
    orientation: "h",
    // yaxis: "y2",
    // mode: "lines",
    name: "Total Packs",
    marker: { color: "#a7cd39" },
    // marker: { color: colorArray },
    // width: 0.3
    // offsetgroup: "1"
  };

  const topOpTime = {
    x: timeChartData,
    y: timeChartLabels,
    type: "bar",
    orientation: "h",
    // yaxis: "y2",
    // mode: "lines",
    name: "Run Time",
    marker: { color: "#7f9b33" },
    // marker: { color: colorArray },
    // width: 0.3
    // offsetgroup: "1"
  };

  // const data = {
  //   x: chartLabels,
  //   y: chartData && chartData.packData,
  //   type: "bar",
  //   yaxis: "y2",
  //   // mode: "lines",
  //   name: "Packs Produced",
  //   // marker: { color: "#0000ff" },
  //   // marker: { color: colorArray },
  //   // width: 0.3
  //   offsetgroup: "2"
  // }

  const formattedChartData = [topOp];
  const formattedTimeChartData = [topOpTime];

  const options2 = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
      font: {
        family: "sans-serif",
        size: 16,
        color: "#fff",
      },
    },
    xaxis: {
      tickfont: {
        size: 15,
      },
    },
    yaxis: {
      tickfont: {
        size: 15,
      },
      // ticklabeloverflow: 'allow',
      ticklabelposition: 'inside'
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const options1 = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
      font: {
        family: "sans-serif",
        size: 16,
        color: "#fff",
      },
    },
    xaxis: {
      tickfont: {
        size: 15,
      },
    },
    yaxis: {
      tickfont: {
        size: 15,
      },
      // ticklabeloverflow: 'allow',
      ticklabelposition: 'inside'
    }, paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ?
    (
      <div>
        <Row>
          <Col xs="12" md="6">
            <div className="chart" style={{ height: "auto" }}>
              <div class="chart-container-bar">
                <Skeleton variant="rectangular" height={400} />
              </div>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="chart" style={{ height: "auto" }}>
              <div class="chart-container-bar">
                <Skeleton variant="rectangular" height={400} />

              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
    : (
      <div>
        {isLoading ? (
          "Loading..."
        ) : (
          <Row>
            <Col xs="12" md="6">
              <div className="chart" style={{ height: "auto" }}>
                <div class="chart-container-bar">
                  <Plot
                    data={formattedChartData}
                    className="w-100 h-100"
                    useResizeHandler
                    layout={options2}
                    config={config}
                  />
                </div>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="chart" style={{ height: "auto" }}>
                <div class="chart-container-bar">
                  <Plot
                    data={formattedTimeChartData}
                    className="w-100 h-100"
                    useResizeHandler
                    layout={options1}
                    config={config}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
}

export default OpPerformance;
