import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { rgb } from "d3";
import deepEqual from "utils/deepequal";
import { Skeleton } from "@mui/material";

function DailyAverageGiveAway({ filtersData, setFiltersData, clientId }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({ ...filtersData });

  useEffect(() => {
    if (deepEqual(filtersData, filter)) return;
    setFilter({ ...filtersData });
  }, [filtersData, clientId]);

  useEffect(() => {
    setIsLoading(true);
    setChartData([]);
    setChartLabels([]);
    axios
      .get(`${baseUrl}/api/mining/avg-GA-data`, {
        params: { ...filter, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        let labelsSet = new Set();
        let formattedData = [];

        // **Extract overall data**
        let overallData = res.data.response;
        let overallPercentageGAData = res.data.percentageGAData;

        // **Collect unique dates from overall data**
        overallData.forEach((item) => {
          labelsSet.add(item.x);
        });

        // **Extract per-line data**
        const lines = Object.keys(res.data.responsePerLine);

        // **Collect unique dates from per-line data**
        lines.forEach((line) => {
          res.data.responsePerLine[line].forEach((item) => {
            labelsSet.add(item.x);
          });
        });

        // **Convert labelsSet to sorted array**
        let labels = Array.from(labelsSet).sort(
          (a, b) => new Date(a) - new Date(b)
        );
        setChartLabels(labels);

        // **Prepare overall data series**
        let overallDateValueMap = {};
        overallData.forEach((item) => {
          overallDateValueMap[item.x] = item.averageGA;
        });
        let overallYValues = labels.map(
          (label) => overallDateValueMap[label] || null
        );
        formattedData.push({
          x: labels,
          y: overallYValues,
          type: "scatter",
          mode: "lines+markers",
          name: "Overall Total GA",
          line: {
            color: "#5661c8",
            width: 2,
          },
        });

        // **Prepare per-line data series**
        lines.forEach((line) => {
          const lineData = res.data.responsePerLine[line];

          let dateValueMap = {};
          lineData.forEach((item) => {
            dateValueMap[item.x] = item.averageGA;
          });

          let yValues = labels.map((label) => dateValueMap[label] || null);

          formattedData.push({
            x: labels,
            y: yValues,
            type: "scatter",
            mode: "lines+markers",
            name: `Total GA - ${line}`,
            line: {
              width: 2,
            },
          });
        });

        // **Prepare overall percentage GA data series**
        let overallPercentageDateValueMap = {};
        overallPercentageGAData.forEach((item) => {
          overallPercentageDateValueMap[item.x] = item.percentageGA;
        });
        let overallPercentageYValues = labels.map(
          (label) => overallPercentageDateValueMap[label] || null
        );
        formattedData.push({
          x: labels,
          y: overallPercentageYValues,
          type: "scatter",
          mode: "lines+markers",
          name: "Overall Percentage GA",
          yaxis: "y2",
          line: {
            dash: "dot",
            color: "#fcba03",
          },
        });

        setChartData(formattedData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  }, [filter, clientId]);

  const options2 = {
    autosize: true,
    showlegend: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: {
      title: "Daily Total GA (in KG)",
    },
    yaxis2: {
      title: "Percentage GA",
      overlaying: "y",
      side: "right",
    },
    xaxis: {},
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false,
  };

  return isLoading ? (
    <Card className="shadow h-100">
      <CardHeader
        className="bg-transparent"
        style={{
          paddingBottom: "0px",
          paddingTop: "10px",
        }}
      >
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              <Skeleton width={200} height={60} />
            </h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Skeleton variant="rectangular" width={"100%"} height={450} />
      </CardBody>
    </Card>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">Give Away Analysis</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {chartLabels.length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div className="chart-container-bar">
              {chartData && (
                <Plot
                  data={chartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={options2}
                  config={config}
                  onRelayout={(e) => {
                    if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                      setFiltersData({
                        ...filtersData,
                        start_date: new Date(e["xaxis.range[0]"]).toISOString(),
                        end_date: new Date(e["xaxis.range[1]"]).toISOString(),
                      });
                    }
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default DailyAverageGiveAway;
