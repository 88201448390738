import React, { useState, useEffect } from "react";
import { Col, Card, Spinner, Button } from "reactstrap";
import Table from "views/shared/Table";
import { Grid, TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import AddNewModal from "views/shared/AddNewModal";
import ConfirmationModal from "views/shared/ConfirmationModal";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";

function MachinesTable({ filtersData }) {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    key: null,
    title: null,
    modalData: null,
    modalButtonText: null,
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({
    title: "",
    body: "",
    confirmText: "",
  });
  const [deleteUserData, setDeleteUserData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setTableData("Loading...");
    fetchData();
  }, [filtersData, page, rowsPerPage]);

  const fetchData = () => {
    axios
      .get(`${baseUrl}/api/admin/machineTypes`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res);
        setTableData(res.data.machines);
        setCount(res.data.machines.length);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      });
  };

  const tableColumns = [
    {
      id: "label",
      title: "Label",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "imagePath",
      title: "Image Path",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "action",
      title: "ACTION",
      className: "sort text-right custom-padding",
      onClick: null,
      render: (record) => renderHtml("action", record),
    },
  ];

  const renderHtml = (key, record) => {
    switch (key) {
      case "action":
        return (
          <>
            <i
              className="text-info btn fa fa-edit px-1 py-0 m-0"
              onClick={() => {
                setOpenModal(true);
                setInfoModalData(
                  {
                    key: "machineType",
                    title: "Update Machine Type",
                    modalData: record,
                    modalButtonText: "Update",
                  },
                );
              }}
            />
            <i
              className="text-danger btn fa fa-trash px-1 py-0 m-0"
              onClick={() => {
                setConfirmationModalData({
                  title: `Delete machine`,
                  body: "Are you sure you want to delete this machine type?",
                  confirmText: "Confirm",
                });
                setDeleteUserData(record);
                setOpenConfirmationModal(true);
              }}
            />
          </>
        );

      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    axios
      .delete(`${baseUrl}/api/admin/machineTypes/${deleteUserData.id}`, {
        headers: authHeader(),
      })
      .then(() => {
        fetchData();
        setIsDeleting(false);
        setOpenConfirmationModal((prevState) => !prevState);
        toast.success("Machine Type deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        setIsDeleting(false);
        toast.error("Error deleting machine type");
      });
  };

  return (
    <>
      <Col className="h-100">
        <Card className="shadow h-100">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3 className="px-4 pt-4" style={{ color: "#a7ce39" }}>
              Machine Types
            </h3>
            <div className="mr-4 pt-3">
              <Button
                style={{ backgroundColor: "#a7ce39", border: "none" }}
                className="px-4"
                size="sm"
                onClick={() => {
                  setOpenModal(true);
                  setInfoModalData({
                    key: "machineType",
                    title: "Add Machine Type",
                    modalData: null,
                    modalButtonText: "Add",
                  });
                }}
              >
                Add
                <i className="fa fa-plus ml-2" />
              </Button>
            </div>
          </Grid>

          <Table
            values={tableData.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )}
            columns={tableColumns}
            tableClasses={{
              table:
                "table align-items-center table-flush table-sm border-bottom text-white table-custom",
              thead: "",
              tbody: "list text-muted",
            }}
            isLoading={tableData === "Loading..."}
          />
          {tableData === "Loading..." ?
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 20,
              marginRight: 20,
            }}>
              <Skeleton variant="rectangular" width={150} />
              <Skeleton variant="rectangular" width={100} />
              <Skeleton variant="rectangular" width={20} />
              <Skeleton variant="rectangular" width={20} />
            </div> :
            <TablePagination
              component="div"
              className="text-muted"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }


        </Card>
      </Col>
      {openModal && (
        <AddNewModal
          isOpen={openModal}
          toggle={() => setOpenModal((prevState) => !prevState)}
          modalKey={infoModalData.key}
          modalTitle={infoModalData.title}
          modalData={infoModalData.modalData}
          modalButtonText={infoModalData.modalButtonText}
          fetchData={fetchData}
        />
      )}
      <ConfirmationModal
        isOpen={openConfirmationModal}
        toggle={() => setOpenConfirmationModal((prevState) => !prevState)}
        title={confirmationModalData.title}
        body={confirmationModalData.body}
        confirmText={confirmationModalData.confirmText}
        modalData={deleteUserData}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
      />
    </>
  );
}

export default MachinesTable;
