import React, { useState, useEffect } from "react";
// import Table from "views/shared/Table";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { Col, Spinner } from "reactstrap";
import { TablePagination } from "@material-ui/core";
import SortTable from "views/shared/SortTable";
import { Skeleton } from "@mui/material";

function OperatorsTable({ clientId, timePeriod }) {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [sort, setSort] = useState({ order: 'desc', orderBy: 'efficiency' })

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        `${baseUrl}/api/operatorPerformance/employeeTableData/${clientId}`,
        {
          params: { ...timePeriod, page, size: rowsPerPage, order: sort.order, orderBy: sort.orderBy },
          headers: authHeader(),
        }
      )
      .then((res) => {
        setTableData(res.data.tableData);
        setCount(res.data.count);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      });
    return () => {
      console.log("Log");
    };
  }, [clientId, timePeriod, page, rowsPerPage, sort]);

  const tableColumns = [
    {
      id: "opName",
      title: "Operator",
      className: "sort",
      onClick: null,
      render: null,
      sort: true,
    },
    {
      id: "efficiency",
      title: "Efficiency",
      className: "sort",
      onClick: null,
      render: null,
      sort: true,
    },
    {
      id: "totalPacks",
      title: "Total Packs",
      className: "sort",
      onClick: null,
      render: null,
      sort: true,
    },
    {
      id: "averageSpeed",
      title: "Average Speed",
      className: "sort",
      onClick: null,
      render: null,
      sort: true,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (orderBy) => {
    setSort((prevState) => ({
      order:
        prevState.order === 'asc' &&
          prevState.orderBy === orderBy
          ? 'desc'
          : 'asc',
      orderBy: orderBy
    }));
    setPage(0)
  };

  return (<div>
    <SortTable
      values={tableData}
      columns={tableColumns}
      tableClasses={{
        table:
          "table align-items-center table-flush table-sm border-bottom text-white table-custom",
        thead: "",
        tbody: "list table-td-color",
      }}
      sort={sort}
      handleSort={handleSort}
      isLoading={isLoading}
    />
    {
      isLoading
        ?
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 20,
          marginRight: 20,
        }}>
          <Skeleton variant="rectangular" width={150} />
          <Skeleton variant="rectangular" width={100} />
          <Skeleton variant="rectangular" width={20} />
          <Skeleton variant="rectangular" width={20} />
        </div> :
        <TablePagination
          component="div"
          className="text-muted"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    }

  </div>)
}

export default OperatorsTable;
