import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { Col, Spinner } from "reactstrap";
import { Skeleton } from "@mui/material";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        style={{ color: "#a7cd39" }}
        size={200}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h2"
          component="div"
          style={{ color: "#a7cd39" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({ clientId, timePeriod }) {
  const [progress, setProgress] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${baseUrl}/api/operatorPerformance/overallPerformance/${clientId}`,
        {
          params: { ...timePeriod },
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log(res);
        setProgress(res.data.averageEfficiency);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [clientId, timePeriod]);

  return isLoading ? (
    <Col xs="12" className="text-center my-5">
      <Skeleton variant="circular" width={240} height={240} />
    </Col>
  ) : (
    <CircularProgressWithLabel value={progress} />
  );
}
