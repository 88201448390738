import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import BarComponent from "./BarComponent";
import LineChartComponent from "./LineChartComponent";
import { Skeleton } from "@mui/material";

function AnalyticsComponent({ title, barData, lineChartData, isLoading = true }) {
  return isLoading ? <Card className="shadow w-100 mb-4" style={{
    minHeight: '500px'
  }}>
    <CardHeader className="bg-transparent">
      <h2 className="mb-0 text-color-mw">{title}</h2>
    </CardHeader>
    <CardBody style={{
      paddingInline: '40px !important'
    }} >
      <Skeleton variant="rectangular" height={400} />
    </CardBody>
  </Card > : (
    <Card className="shadow w-100 mb-4">
      <CardHeader className="bg-transparent">
        <h2 className="mb-0 text-color-mw">{title}</h2>
      </CardHeader>
      <CardBody className="p-0">
        <BarComponent barData={barData} title={title} />
        <LineChartComponent lineChartData={lineChartData} title={title} />
      </CardBody>
    </Card>
  );
}

export default AnalyticsComponent;
