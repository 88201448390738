import { Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Plot from "react-plotly.js";
import {
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  Input,
  Tooltip,
} from "reactstrap";

function OperatorsProductsGraph({
  topValues,
  title,
  isLoading,
  performanceKey,
  setPerformanceKey,
}) {
  const [data, setData] = useState([]);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    const lightColors = [
      "#006400", // Light Green
      "#ffa07a", // Light Salmon
      "#11CDEF", // Light Sky Blue
      "#f08080", // Light Coral
      "#ffb6c1", // Light Pink
      "#fafad2", // Light Goldenrod Yellow
      "#e0ffff", // Light Cyan
      "#e6e6fa", // Lavender
      "#afeeee", // Pale Turquoise
      "#b0c4de", // Light Steel Blue
      "#98fb98", // Pale Green
      "#eee8aa", // Pale Goldenrod
      "#d3d3d3", // Light Gray
      "#add8e6", // Light Blue
      "#ffe4e1", // Misty Rose
    ];
    if (topValues) {
      const newData = [];
      topValues.forEach((topValue, idx) => {
        let barData = {
          x: topValue.x,
          y: topValue.y,
          type: "bar",
          // yaxis: "y2",
          mode: "lines",
          text: topValue.x.map((label) => label),
          textfont: {
            size: 15,
            color: "white",
            family: "Raleway",
          },
          name: topValue.name,
          marker: { color: lightColors[idx] },
          width: 0.3,
          texttemplate: "   %{text}", // Custom hover text template
          hovertemplate: "<b>%{text}</b>: %{y}<extra></extra>", // Custom hover text template
        };

        newData.push(barData);
      });
      setData(newData);
    }
  }, [topValues, title, isLoading]);

  const layout = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    yaxis: {
      title: performanceKey === "ppm" ? "PPM" : "Weight (Kg)",
    },
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
    // xaxis: {
    //   tickvals: data[0]?.x || [], // Ensure tickvals are set only when data is available
    //   ticktext: (data[0]?.x || []).map(label => label.length > 10 ? label.substring(0, 10) + "..." : label),
    // },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? <Card className="shadow w-100 mb-4">
    <CardHeader className="bg-transparent" style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <h2 className="mb-0 text-color-mw">
        <Skeleton width={100} height={50} />
      </h2>
      <Nav className="justify-content-end" pills>
        <NavItem>
          <Skeleton width={100} height={50} />
        </NavItem>
      </Nav>
    </CardHeader>
    <CardBody className="p-0">
      <Skeleton variant="rectangular" width={"100%"} height={450} />
    </CardBody>
  </Card> : <Card className="shadow w-100 mb-4">
    <CardHeader className="bg-transparent">
      <h2 className="mb-0 text-color-mw">
        {title}
        <FaInfoCircle
          id="cost-chart-tooltip"
          fontSize={"18px"}
          className="ml-2"
        />
        <Tooltip
          placement={"top"}
          isOpen={tooltip}
          target={`cost-chart-tooltip`}
          toggle={() => {
            setTooltip((st) => !st);
          }}
        >
          How operators are performing for different products
        </Tooltip>
      </h2>
      <Nav className="justify-content-end" pills>
        <NavItem>
          <Input
            placeholder="Performance Basis"
            name="performanceKey"
            type="select"
            value={performanceKey}
            className="textBg"
            bsSize="sm"
            style={{ color: "#000000" }}
            onChange={(e) => setPerformanceKey(e.target.value)}
          >
            {["ppm", "weight"].map((option, idx) => {
              return (
                <option key={idx} value={option}>
                  {option}
                </option>
              );
            })}
          </Input>
        </NavItem>
      </Nav>
    </CardHeader>
    <CardBody className="p-0">
      {isLoading ? (
        <div className="m-5">Loading...</div>
      ) : (
        <Plot
          data={data}
          className="w-100 h-100"
          useResizeHandler
          layout={layout}
          config={config}
        // onRelayout={(e) => {
        //   if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
        //     setFiltersData({
        //       ...filtersData,
        //       start_date: new Date(e["xaxis.range[0]"]).toISOString(),
        //       end_date: new Date(e["xaxis.range[1]"]).toISOString(),
        //     });
        //   }
        // }}
        />
      )}
    </CardBody>
  </Card>
}

export default OperatorsProductsGraph;
