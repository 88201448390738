import React, { useState, useEffect } from "react";
// import { Chart } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
  Tooltip,
} from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import moment from "moment";
import deepEqual from "utils/deepequal";
import { FaInfoCircle } from "react-icons/fa";
import { Skeleton } from "@mui/material";

export default function Lines({
  // title,
  filtersData,
  setFiltersData,
  clientId,
}) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState();
  const [duration, setDuration] = useState("");
  const [tooltip, setTooltip] = useState(false);

  const onChange = (event) => {
    const value = event.target.value;
    setDuration(value);
    if (value === "") {
      setFilter({
        ...filtersData,
      });
    } else if (value === "Last Week") {
      const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    } else if (value === "Last Month") {
      const start = moment().subtract(1, "months").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    } else if (value === "Last Year") {
      const start = moment().subtract(1, "years").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    }
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "#89aa9f",
        },
      },
    },
    cutout: "60%",
  };
  useEffect(() => {
    setDuration("");
    if (deepEqual(filtersData, filter)) return;
    setFilter({ ...filtersData });
  }, [filtersData, clientId]);

  useEffect(() => {
    if (!filter) return;
    setIsLoading(true);
    setChartLabels([]);
    setChartData([]);
    axios
      .get(`${baseUrl}/api/dashboard/charts/line`, {
        params: { ...filter, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        let labels = [];
        let data = [];
        res.data.data.pieChunks.forEach((item) => {
          // labels.push(item.x.split("T")[0]);
          labels.push(item.x);
          data.push(item.y);
        });
        setChartLabels(labels);
        setChartData(data);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        setChartLabels([]);
        setChartData([]);
      });
  }, [filter, clientId]);

  const data = {
    labels: chartLabels && chartLabels,
    datasets: [
      {
        label: "# of Votes",
        data: chartData && chartData,
        backgroundColor: [
          "#5661C8",
          "#25BCDD",
          "#21E57C",
          "#F4F842",
          "#F06F26",
          "#F32A36",
          "#B7B7B7",
        ],
      },
    ],
  };

  const linesData = {
    labels: chartLabels && chartLabels,
    values: chartData && chartData,
    type: "pie",
    name: "Packs w.r.t Lines",
    hoverinfo: "label+percent",
    hole: 0.4,
  };

  const formattedChartData = [linesData];

  const options2 = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    annotations: {
      font: {
        size: 20,
      },
      showarrow: false,
      // text: 'GHG',
      x: 0.17,
      y: 0.5,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true,
  };

  return isLoading ? (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              Lines
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Skeleton width={100} height={50} />
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0" >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 20,
        }}>

          <Skeleton variant="text" width={60} height={40} />
          <Skeleton variant="text" width={60} height={40} />
          <Skeleton variant="text" width={60} height={40} />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
        }}>
          <Skeleton variant="circular" width={215} height={215} />
        </div>
      </CardBody>
    </Card>
  ) : (
    <Card className="shadow h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              Lines
              <FaInfoCircle
                id="lines-pie-chart-tooltip"
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={`lines-pie-chart-tooltip`}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                Line Pie Chart: Displays the top 5 lines by the total number of
                packs produced.
              </Tooltip>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="country_id"
                  type="select"
                  value={duration}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={onChange}
                >
                  {["", "Last Week", "Last Month", "Last Year"].map(
                    (option, idx) => {
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    }
                  )}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        {chartLabels.length > 0 ? (
          <div className="chart py-2 pt-3" style={{ height: "auto" }}>
            <div
              class="chart-container"
              style={{
                position: "relative",
                margin: "auto",
              }}
            >
              {/* {console.log("Lines data")}
            {console.log(formattedChartData)} */}
              {/* <Chart options={options} type="doughnut" data={data} /> */}
              <Plot
                data={formattedChartData}
                className="w-100 h-100"
                useResizeHandler
                layout={options2}
                config={config}
                onClick={(e) => {
                  console.log("onClick", e.points[0].label);
                  setFiltersData({
                    ...filtersData,
                    line: e.points[0].label,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}
